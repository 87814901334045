<template>
	<div class="page questionbank_page">
		<div class="block30"></div>
		<div class="w1200 questionbank">
			<div class="leftbox">
				<div class="label_box1">
					<div class="labeldiv fontsize16">类型</div>
					<div class="datalist">
						<div class="itemobj fontsize16" v-for="(item,index) in typeList" :key="index" @click="handleType(index)" :class="{'active':index==typeindex}">
							{{item.name}}
						</div>
					</div>
				</div>
				<div class="label_box1">
					<div class="labeldiv fontsize16">标签</div>
					<div class="datalist">
						<div class="itemobj fontsize16" v-for="(item,index) in typeList1" :key="index" @click="handleType1(index)" :class="{'active':index==typeindex1}">
							{{item.title}}
						</div>
					</div>
				</div>
				<div class="label_box1">
					<div class="labeldiv fontsize16">难度</div>
					<div class="datalist">
						<div class="itemobj fontsize14" v-for="(item,index) in typeList2" :key="index" @click="handleType2(index)" :class="{'active':index==typeindex2}">
							{{item}}
						</div>
					</div>
				</div>
				<div class="label_box1">
					<div class="labeldiv fontsize16">输入题号</div>
					<div class="inputbox">
						<el-input placeholder="请输入题号" v-model="formData.questNumber"></el-input>
					</div>
				</div>
				<div class="label_box1">
					<div class="labeldiv fontsize16">输入标题</div>
					<div class="inputbox">
						<el-input placeholder="请输入标题" v-model="formData.title"></el-input>
					</div>
				</div>
				<div class="label_box1">
					<div class="labeldiv fontsize16"></div>
					<el-button type="primary" @click="handlesearch">点击搜索</el-button>
				</div>
				
			</div>
			<div class="block20"></div>
			<div class="rightbox">
				<div class="zuoyebox">
					<div class="item1 fontsize14">
						<div class="text0">状态</div>
						<div class="text1">题号</div>
						<div class="text2">题目名称</div>
						<div class="text3">标签</div>
						<div class="text4">难度</div>
					</div>
					<div class="itemobj" v-for="(item,index) in dataList" :key="index" @click="toquesdetail(item.id)">
						<div class="text0">
							<div v-if="item.hlQuestRecordVos.length<=0" class="text">·</div>
							<img v-if="item.hlQuestRecordVos.length>0" class="icon" src="../../assets/static/duigou.png"/>
						</div>
						<div class="text1">{{item.questNumber}}</div>
						<div class="text2">{{item.title}}</div>
						<div class="text3 fontsize14">
							<el-tag :type="tagcolor[Math.floor(Math.random() * 3)]" v-for="(tagitem,tagindex) in item.infoTags" :key="tagindex">{{tagFormat(tagitem)}}</el-tag>
						</div>
						<div class="text4">
							<span class="span1">{{difficultyList[item.difficulty]}}</span>
						</div>
					</div>
				</div>
				<div class="paginationbox">
				    <el-pagination
				      @current-change="handleCurrentChange"
				      :current-page.sync="formData.currentPage"
				      :page-size="formData.pageSize"
				      layout="prev, pager, next, jumper"
				      :total="total">
				    </el-pagination>
				</div>
			</div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
export default {
	data() {
		return {
			typeList:[{name: '全部',id: null },{name: '编程题',id: 0 },{name: '选择题',id: 1 }],//类型
			typeindex:0,//
			typeList1:[{title: '全部',id: null }],//标签类型
			typeindex1:0,
			typeList2:[],//难度
			typeindex2:0,
			dataList:[],//题目列表
			formData: {
				currentPage: 1,
				pageSize: 10,
				title: '',
				questNumber: null,
				classify: null,
			},
			total: 0,
			tagcolor:["","success","danger",'warning'],//颜色
		};
	},
	mounted: function() {
		if(!this.isLogin){
			this.$message.error("请先返回首页登录！")
			return false;
		}
		//获取题目类型
		this.gethlQuestType()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole','difficultyList']),
	},
	methods: {
		//作业题目详情
		toquesdetail(uid){
			localStorage.setItem("trainformData",JSON.stringify(this.formData))
			this.$router.push({
				path: "/questionDetail",
				query:{
					uid:uid,
					type:"ques"
				}
			});
		},
		//获取题目类型
		gethlQuestType(){
			var _this = this 
			var params = {
				currentPage: 1,
				pageSize: 888
			}
			this.$http.post('app/hlexam/hlQuestType/index', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.typeList1 = _this.typeList1.concat(res.data.records)
					//难度类型
					var typeList2 = JSON.parse(JSON.stringify(_this.difficultyList))
					typeList2.unshift('全部')
					_this.typeList2 = typeList2
					//获取题目
					_this.getdatalist()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//标签
		tagFormat(tag){
			const obj = this.typeList1.find((ifitem) => { //这里的options就是上面遍历的数据源
				return ifitem.typeCode == tag; //筛选出匹配数据
			});
			if(obj){
				return obj.title
			}else{
				return tag
			}
		},
		//搜索
		handlesearch(){
			this.formData.currentPage = 1
			this.getdatalist()
		},
		//获取题目
		getdatalist(){
			var _this = this
			//查询内容
			var params = this.formData
			if(this.typeindex==0){
				params["classify"] = null
			}else{
				params["classify"] = this.typeList[this.typeindex].id
			}
			if(this.typeindex1==0){
				params["infoTags"] = null
			}else{
				params["infoTags"] = this.typeList1[this.typeindex1].typeCode
			}
			if(this.typeindex2==0){
				params["difficulty"] = null
			}else{
				params["difficulty"] = Number(this.typeindex2 -1)
			}
			params["userUuid"] = this.userInfo.uuid
			
			showLoading()
			this.$http.post('app/hlexam/hlQuestInfo/index', params).then(function(res) {
				hideLoading()
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					//获取类型json
					records.forEach((item, index) => {
						try{
							item["infoTags"] = JSON.parse(item.infoTags)
						}catch(e){
							item["infoTags"] = []
						}
					});
					_this.total = res.data.total;
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//下标
		handleType(type){
			this.typeindex = type
		},
		//标签
		handleType1(type){
			this.typeindex1 = type
		},
		//难度
		handleType2(type){
			this.typeindex2 = type
		},
		//点击页数
		handleCurrentChange(currentPage) {
			this.formData.currentPage = currentPage;
			this.getdatalist();
		}
		
		
	}
};
</script>
<style lang="scss" scoped></style>
